import React, { useState, useEffect, useRef } from 'react';
import { firestore, auth, storage } from './firebase-config';
import { createUserWithEmailAndPassword, fetchSignInMethodsForEmail } from 'firebase/auth';
import { collection, getDocs } from 'firebase/firestore';
import { Link, useParams  } from 'react-router-dom';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, uploadBytes, getDownloadURL } from 'firebase/storage';
import './App.css'
import ReactMarkdown from 'react-markdown';
import tmc_logo from './assets/tmc_logo.png';
import logo2 from './assets/logo1w.png';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

const CompanyPage = () => {
    const {jobId} = useParams();
    const [jobs, setJobs] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
  
    useEffect(() => {
      // Fetch jobs from backend
      fetchJobs();
    }, []);
  
    const fetchJobs = async () => {
      // Replace this URL with your actual API endpoint
      const snapshot = await getDocs(collection(firestore, 'jobs'));
      const jobsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setJobs(jobsData);
    };
  
    const handleSearch = (event) => {
      setSearchTerm(event.target.value);
    };
  
    return (
      <div className="App">
        <input type="text" placeholder="Search for jobs..." onChange={handleSearch} />
        <div className="jobs-grid">
        {jobs.filter(job => job.title.toLowerCase().includes(searchTerm.toLowerCase())).map(job => (
          <div key={job.id} className="job-entry">
            <Link to={`/${job.id}`}>{job.title}</Link>
            <p className="job-description">{job.description}</p>
          </div>
        ))}
        </div>
      </div>
    );
  };

const JobDetailPage = () => {
    const [job, setJob] = useState(null);
    const { jobId } = useParams();
    const targetRef = useRef(null);
    const [isApplied, setIsApplied] = useState(false);
    const [isAppliedAdditional, setIsAppliedAdditional] = useState(false);
    const [logoUrl, setLogoUrl] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [fieldDefinitions, setFieldDefinitions] = useState([]);
    const [formDataAdditional, setFormDataAdditional] = useState({});

    // Function to scroll to the container
    const scrollToTarget = () => {
        if (targetRef.current) {
            targetRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };
  
    useEffect(() => {
      const fetchJob = async () => {
        const docRef = doc(firestore, 'jobs', jobId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setJob(docSnap.data());
        } else {
          console.log("No such job!");
        }
      };
  
      fetchJob();
    }, [jobId]);

    useEffect(() => {
        // Fetch field definitions from Firebase
        const fetchFieldDefinitions = async () => {
          try {
            const querySnapshot = await getDocs(collection(firestore, `jobs/${jobId}/formFields`)); 
            const fields = [];
            const initialFormData = {};
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                fields.push({ id: doc.id, ...data });
                        // Initialize formData with appropriate defaults
                    initialFormData[data.name] =
                    data.type === 'checkbox'
                        ? false
                        : data.type === 'number'
                        ? ''
                        : '';
            });
            fields.sort((a, b) => a.order - b.order);
            setFieldDefinitions(fields);
            setFormDataAdditional(initialFormData);
          } catch (error) {
            console.error('Error fetching field definitions:', error);
          }
        };
    
        fetchFieldDefinitions();
      }, []);

    const [formData, setFormData] = useState({
        email: '',
        name: '',
        age: '',
        phone:'',
        yearsOfExperience: '',
        designation: '',
        currentCompany: '',
        resume: null,
        job_id: jobId
    });

    

    const [errors, setErrors] = useState({
        phone: '',
      });


    const [filename, setFilename] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        // Special validation for phone number to allow only digits and restrict to 10
        if (name === 'phone') {
            if (/^\d*$/.test(value)) {
            setFormData(prev => ({ 
                ...prev, 
                [name]: value 
            }));
            setErrors({ ...errors, phone: '' });
            }
            else {
                alert("Mobile number can only be digits")
            }
        } else {
            setFormData(prev => ({ ...prev, [name]: value }));
        }

        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleInputChangeAdditional = (event) => {
        const { name, value, type, checked } = event.target;

        // Special validation for phone number to allow only digits and restrict to 10
        setFormDataAdditional(prev => ({
            ...prev,
            [name]:
        type === 'checkbox' ? checked : type === 'number' ? value : value,
        }));
    };

    // const handleChange = (id, value) => {
    //     setFormData(prev => ({ ...prev, [id]: value }));
    // };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            // Check if the file is a PDF
            if (file.type !== 'application/pdf') {
                alert('Please upload a PDF file.');
                // Reset the file input
                event.target.value = null;
                return;
            }
            setFormData(prev => ({
                ...prev,
                resume: file
            }));
            setFilename(file.name);
        }
        setFormData(prev => ({
            ...prev,
            resume: file
        }));
        setFilename(file.name);
    };

    const handleSubmit =async (event) => {
        event.preventDefault();
        setIsSubmitting(true); // Start submitting
        const data = new FormData();
        for (const key in formData) {
            data.append(key, formData[key]);
        }
        try{
            // const response = await fetch('https://antgpt-420615.uc.r.appspot.com/submit-form', {
            //     method: 'POST',
            //     body: data,
            // });
            // const resultData = await response.json();

            // if (resultData.message) {
                
                uploadToFirebase(formData.resume,formData.email)
                // alert(resultData.message);
                const{resume, ...otherData} = formData;
                const saveData = {
                    ...otherData // Spread all other form data
                    // extractedResume: resultData.extractedText // using the extracted text from server
                };
                await setDoc(doc(firestore, `jobs/${jobId}/${formData.email}/application_form`), saveData);
                // alert(resultData.extractedText);
                // await createUserWithEmailAndPassword(
                //     auth,
                //     formData.email,
                //     formData.phone // Use phone number as password
                //   );
                setIsSubmitting(false);
                setIsApplied(true);
            // } else {
            //     alert('Error: ' + resultData.errors);
            // }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred: ' + error.message);
        }
        setIsSubmitting(false);
    };


    const handleSubmitAdditional =async (event) => {
        event.preventDefault();
        setIsSubmitting(true); // Start submitting
        const data = new FormData();
        for (const key in formDataAdditional) {
            data.append(key, formDataAdditional[key]);
        }
        try{
            // const response = await fetch('https://antgpt-420615.uc.r.appspot.com/submit-form', {
            //     method: 'POST',
            //     body: data,
            // });
            // const resultData = await response.json();

            // if (resultData.message) {
                
                // uploadToFirebase(formData.resume,formData.email)
                // alert(resultData.message);
                const{resume, ...otherData} = formDataAdditional;
                const saveDataAdditional = {
                    ...otherData // Spread all other form data
                    // extractedResume: resultData.extractedText // using the extracted text from server
                };
                await setDoc(doc(firestore, `jobs/${jobId}/${formData.email}/additional_form`), saveDataAdditional);
                // alert(resultData.extractedText);
                const userExist = await fetchSignInMethodsForEmail(auth, formData.email);
                console.log(userExist)
                if (userExist.length > 0) {
                    console.log("User Already Exist")
                } else {
                    await createUserWithEmailAndPassword(
                        auth,
                        formData.email,
                        formData.phone // Use phone number as password
                    );
                    console.log("User Created:", formData.email)
                }
                setIsSubmitting(false);
                setIsAppliedAdditional(true);
            // } else {
            //     alert('Error: ' + resultData.errors);
            // }
        } catch (error) {
            if (error.code === 'auth/email-already-in-use') {
                console.error('Error:', error);
                setIsSubmitting(false);
                setIsAppliedAdditional(true);
            } else {
            // Handle other errors
            console.error('Error:', error);
            alert('An error occurred: ' + error.message);
            }
        }
        setIsSubmitting(false);
    };



    // Utility to upload files to Firebase Storage
    async function uploadToFirebase (file, email) {
        const storageRef = ref(storage, `resume/${jobId}/${email}`); // Folder named 'uploads'
        // const storageRef = ref(storage, `uploads/${jobId}/${userId}/${fileName}`)
        const metadata = {
            contentType: file.type,  // Use the MIME type from the file Blob object
        };

        const fileRef = ref(storage, `resume/${jobId}/${email}/${filename}`);
        const uploadTask = uploadBytesResumable(fileRef, file);
    };


    

    useEffect(() => {
        const fetchLogoUrl = async () => {
        try {
            const logoRef = ref(storage,`uploads/${jobId}/logo/logo.jpg`);
            const url = await getDownloadURL(logoRef);
            setLogoUrl(url);
        } catch (error) {
            console.error('Error fetching logo URL:', error);
        }
        };

        fetchLogoUrl();
    }, []);
  
    return(
        <div className='App'>
            <header className='header'>
            {/* <img src={tmc_logo} alt="The Man Company" className="logo"/> */}
            <a href={job ? job.website ? job.website: '' : ''} target="_blank" rel="noopener noreferrer">
            <img src={logoUrl!=='' ? logoUrl: logo2} alt={job ? job.company ? job.company: 'AnAnt' : 'AnAnt'} className="logo"/>
            </a>
            {job ? (job.company ? <h2>{job.company}</h2>: 'AnAnt') : <>Loading....</>}
            
                </header>
            {isApplied===false && (<div className='job-detail-container'>
                {/* <title>The Man Company Jobs</title> */}
                <button type='submit' className='submit-button' onClick={scrollToTarget}>
                    {'Apply Now'}
                </button>
                {job ? (
                <div>
                    <h1>{job.title}</h1>
                    <ReactMarkdown>{`${job.description}`}</ReactMarkdown>
                </div>
                ) : <p>Loading...</p>}
            </div>)}
            {isApplied===false ? (<div className='form-container' ref={targetRef}>
                <form onSubmit={handleSubmit}>
                    <input type="email" name="email" placeholder="Email*" value={formData.email} onChange={handleInputChange} className="input-field" required />
                    <input type="text" name="name" placeholder="Name*" value={formData.name} onChange={handleInputChange} className="input-field" required />
                    <input type="text" name="phone" placeholder="10 Digit Mobile Number*" maxLength="10" minLength="10" value={formData.phone} onChange={handleInputChange} className="input-field" required />
                    <div className="file-input">
                        <label htmlFor="file-upload">
                            <FileUploadOutlinedIcon style={{verticalAlign: 'middle', fontSize:'16px', marginRight:'8px'}}/>
                            <span style={{verticalAlign: 'middle', fontSize:'14px'}}>Upload Resume</span>
                        </label>
                        <input id="file-upload" type="file" name="resume" onChange={handleFileChange} accept="application/pdf" required />
                        {filename && <div>Uploaded File: {filename}</div>}
                    </div>
                    <button type="submit" className="submit-button" disabled = {isSubmitting || isApplied}>
                        {isApplied? 'Applied' : isSubmitting ? 'Processing...' : 'Apply' }
                    </button>
                </form>
                <ReactMarkdown>Facing issues? Contact: **contact@anantgpt.com** </ReactMarkdown>
            </div>): isAppliedAdditional===false?
                (
                    <div className='form-container'>
                        <ReactMarkdown>Please share some additional details</ReactMarkdown>
                        <form onSubmit={handleSubmitAdditional} style={{ margin:"10px", fontSize:"16px"}}>
                            {fieldDefinitions.map((field) => {
                                switch (field.type) {
                                    case 'file':
                                    return (
                                        <div className="file-input" key={field.name}>
                                        <label htmlFor={field.name}>
                                            <FileUploadOutlinedIcon
                                            style={{ verticalAlign: 'middle', fontSize: '16px', marginRight: '8px' }}
                                            />
                                            <span style={{ verticalAlign: 'middle', fontSize: '14px' }}>{field.label}</span>
                                        </label>
                                        <input
                                            id={field.name}
                                            type="file"
                                            name={field.name}
                                            onChange={handleInputChangeAdditional}
                                            required={field.required}
                                        />
                                        {filename && <div>Uploaded File: {filename}</div>}
                                        </div>
                                    );
                                    case 'textarea':
                                    return (
                                        <div key={field.name}>
                                            <label htmlFor={field.name}>{field.label}</label>
                                            <textarea
                                                key={field.name}
                                                name={field.name}
                                                placeholder={field.placeholder}
                                                value={formDataAdditional[field.name] || ''}
                                                onChange={handleInputChangeAdditional}
                                                className="input-field"
                                                required={field.required}
                                                minLength={field.minLength}
                                                maxLength={field.maxLength}
                                            />
                                        </div>
                                        
                                    );
                                    case 'select':
                                        return (

                                            <div key={field.name}>
                                                <label htmlFor={field.name}>{field.label}</label>
                                                <select
                                                    key={field.name}
                                                    name={field.name}
                                                    value={formDataAdditional[field.name] || ''}
                                                    onChange={handleInputChangeAdditional}
                                                    className="input-field"
                                                    required={field.required}
                                                >
                                                    <option value="" disabled>{field.placeholder}</option>
                                                    {field.options.map((option) => (
                                                    <option key={option} value={option}>{option}</option>
                                                    ))}
                                                </select>
                                        </div>
                                        );
                                    case 'checkbox':
                                    return (
                                        <label key={field.name} className="checkbox-field">
                                        <input
                                            type="checkbox"
                                            name={field.name}
                                            checked={formDataAdditional[field.name] || false}
                                            onChange={handleInputChangeAdditional}
                                            required={field.required}
                                        />
                                        {field.label}
                                        </label>
                                    );
                                    default:
                                    return (
                                        <div key={field.name}>
                                            <label htmlFor={field.name}>{field.label}</label>
                                            <input
                                                id={field.name}
                                                type={field.type}
                                                name={field.name}
                                                label={field.label}
                                                placeholder={field.placeholder}
                                                value={formDataAdditional[field.name] || ''}
                                                onChange={handleInputChangeAdditional}
                                                className="input-field"
                                                required={field.required}
                                                minLength={field.minLength}
                                                maxLength={field.maxLength}
                                            />
                                        </div>
                                    );
                                }
                            })}
                            <button type="submit" className="submit-button" disabled = {isSubmitting || isAppliedAdditional}>
                                {isAppliedAdditional? 'Submitted' : isSubmitting ? 'Processing...' : 'Submit' }
                            </button>
                        </form>
                    </div>
                ):
                (<div className='job-detail-container'><ReactMarkdown>Thank you for Applying.</ReactMarkdown> 
                    <ReactMarkdown>We'll react out to you within next 24 hours, please look out for email from the address **contact@anantgpt.com**. Do check your spam as well.</ReactMarkdown>
                    <ReactMarkdown>If you don't hear back from us within 24 hours, please email us at: **contact@anantgpt.com**</ReactMarkdown>
                </div>)
            }
        </div>
    );
};
   
export {CompanyPage, JobDetailPage};