// Import the functions you need from the SDKs you need
import { initializeApp} from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyBfaSZ4ECWmTjW2Mxx3F9aoI_cg5FC9zzk",
    authDomain: "antgpt-420615.firebaseapp.com",
    projectId: "antgpt-420615",
    storageBucket: "antgpt-420615.appspot.com",
    messagingSenderId: "493231910975",
    appId: "1:493231910975:web:2c5f14df25ebe9cf66088a",
    measurementId: "G-G5ZPG0V5FX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

// Initialize Google Auth Provider
// const googleProvider = new GoogleAuthProvider();

// export const signInWithGoogle = async () => {
//     try {
//         const result = await signInWithPopup(auth, googleProvider);
//         // This gives you a Google Access Token. You can use it to access the Google API.
//         const credential = GoogleAuthProvider.credentialFromResult(result);
//         const token = credential.accessToken;
//         // The signed-in user info.
//         const user = result.user;
//         return user;
//     } catch (error) {
//         console.error(error);
//         alert(error.message);
//     }
// };

// Initialize Cloud Firestore and get a reference to the service
const firestore = getFirestore(app);
const storage = getStorage(app);

export { storage, firestore, auth };